import React from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { useRef } from 'react';
import emailjs from 'emailjs-com';
import styled from 'styled-components';

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_6hpx50x',
                'template_onujkgr',
                form.current,
                'uqw-cR9ot6plawTCy'
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
    };

    const Section = styled.div`
        height: auto;
        scroll-snap-align: center;
    `;

    return (
        <Section>
            <section id='contact'>
                <h5>Get in Touch</h5>
                <h2>Contact Me</h2>
                <div className='commonBorder'></div>
                <div className='container contact__container'>
                    <div className='contact__options'>
                        <article className='contact__option'>
                            <MdOutlineEmail className='contact__icon' />
                            <h4>Email</h4>
                            <h5>sungjaehong10@gmail.com</h5>
                            <a
                                href='mailto:sungjaehong10@gmail.com'
                                target='_blank'
                            >
                                Send a message
                            </a>
                        </article>
                        <article className='contact__option'>
                            <AiOutlineLinkedin className='contact__icon' />
                            <h4>LinkedIn</h4>
                            <h5>SungJae Hong</h5>
                            <a
                                href='https://www.linkedin.com/in/sungjaehong/'
                                target='_blank'
                            >
                                Send a message
                            </a>
                        </article>
                    </div>
                    <form ref={form} onSubmit={sendEmail}>
                        <input
                            type='text'
                            name='name'
                            placeholder='Your Name'
                            required
                        />
                        <input
                            type='email'
                            name='email'
                            placeholder='Your Email'
                            required
                        />
                        <textarea
                            name='message'
                            placeholder='Your Message'
                            rows='7'
                            required
                        />
                        <button type='submit' className='btn btn-primary'>
                            Send Message
                        </button>
                    </form>
                </div>
            </section>
        </Section>
    );
};

export default Contact;
