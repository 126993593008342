import React from 'react';
import Resume from '../../assets/JaeHong_Resume.pdf';
import Socials from './Socials';
import './header.css';
import Type from './Type';
import styled from 'styled-components';

const Header = () => {
    const Section = styled.div`
        height: 100vh;
        scroll-snap-align: center;
    `;
    return (
        <Section>
            <header id='header'>
                <div className='container header__container'>
                    <h5>Hello, I'm</h5>
                    <h1>Jae Hong</h1>
                    <div>
                        <Type />
                    </div>
                    <div className='cta'>
                        <a href={Resume} download className='btn'>
                            Resume
                        </a>
                        <a href='#contact' className='btn btn-primary'>
                            Let's Talk
                        </a>
                    </div>
                    <Socials />
                    {/* <div className='myPhoto'>
                        <img src={MyPhoto} alt='Jae Hong' className='jae' />
                    </div> */}

                    <a href='#contact' className='scroll__down'>
                        Scroll Down
                    </a>
                </div>
            </header>
        </Section>
    );
};

export default Header;
