import React from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

const Socials = () => {
    return (
        <div className='header__socials'>
            <a href='https://www.linkedin.com/in/sungjaehong/' target='_blank'>
                <LinkedInIcon />
            </a>
            <a href='https://github.com/sungjah' target='_blank'>
                <GitHubIcon />
            </a>
            <a href='mailto:sungjaehong10@gmail.com' target='_blank'>
                <EmailIcon />
            </a>
        </div>
    );
};

export default Socials;
