import React from 'react';
import {
    FaHtml5,
    FaCss3Alt,
    FaReact,
    FaNodeJs,
    FaGithub,
    FaPython,
    FaJava,
} from 'react-icons/fa';
import { DiJavascript1 } from 'react-icons/di';
import { DiMongodb, DiDjango } from 'react-icons/di';
import { SiC } from 'react-icons/si';
import './skills.css';
import styled from 'styled-components';

const Skills = () => {
    const [state] = React.useState([
        {
            id: 1,
            icon: <FaHtml5 className='commonIcons' />,
            heading: 'HTML',
        },
        {
            id: 2,
            icon: <FaCss3Alt className='commonIcons' />,

            heading: 'CSS',
        },
        {
            id: 4,
            icon: <DiJavascript1 className='commonIcons' />,
            heading: 'Javascript',
        },
        {
            id: 5,
            icon: <FaReact className='commonIcons' />,
            heading: 'React',
        },
        {
            id: 6,
            icon: <DiDjango className='commonIcons' />,
            heading: 'Django',
        },
        {
            id: 7,
            icon: <FaNodeJs className='commonIcons' />,
            heading: 'Node Js',
        },
        {
            id: 9,
            icon: <DiMongodb className='commonIcons' />,
            heading: 'Mongo DB',
        },
        {
            id: 10,
            icon: <FaGithub className='commonIcons' />,
            heading: 'Git',
        },
        {
            id: 11,
            icon: <FaPython className='commonIcons' />,
            heading: 'Python',
        },
        {
            id: 12,
            icon: <FaJava className='commonIcons' />,
            heading: 'Java',
        },
        {
            id: 13,
            icon: <SiC className='commonIcons' />,
            heading: 'C',
        },
    ]);

    const Section = styled.div`
        height: auto;
        scroll-snap-align: center;
    `;
    return (
        <Section>
            <section id='skills'>
                <h5>The Skills I have</h5>
                <h2>My Skills & Experience</h2>
                <div className='commonBorder'></div>
                <div className='container skills__container'>
                    {state.map((info) => (
                        <div className='col-4 bgMain'>
                            <div className='skills__box'>
                                {info.icon}
                                <div className='skills__box-header'>
                                    {info.heading}
                                </div>
                                <div className='skills__box-p'>{info.text}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </Section>
    );
};

export default Skills;
