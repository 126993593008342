import React, { useState } from 'react';
import './educationexperience.css';
import styled from 'styled-components';

const EducationExperience = () => {
    const [activeTab, setActiveTab] = useState('education');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const Section = styled.div`
        height: auto;
        scroll-snap-align: center;
    `;

    return (
        <Section>
            <section>
                <h5>My Background</h5>
                <h2>Education & Work Experience</h2>
                <div className='commonBorder'></div>

                <div className='education-experience-tab'>
                    <div className='tabs-container'>
                        <div className='tabs'>
                            <button
                                className={
                                    activeTab === 'education' ? 'active' : ''
                                }
                                onClick={() => handleTabClick('education')}
                            >
                                Education
                            </button>
                            <button
                                className={
                                    activeTab === 'experience' ? 'active' : ''
                                }
                                onClick={() => handleTabClick('experience')}
                            >
                                Work Experience
                            </button>
                        </div>
                    </div>
                    <div className='tab__content'>
                        {activeTab === 'education' && (
                            <div>
                                <h2 style={{ textDecoration: 'underline' }}>
                                    Education
                                </h2>
                                <div className='education__content'>
                                    <div
                                        id='education'
                                        className='tab-pane-ubc'
                                    >
                                        <h3>University of British Columbia</h3>
                                        <h4>Bachelor of Arts</h4>
                                        <h5>
                                            Major in Sociology and Minor in Law
                                            & Society
                                        </h5>
                                        <h6>2014~2019</h6>
                                    </div>
                                    <div id='bcit' className='tab-pane-bcit'>
                                        <h3>
                                            British Columbia Institute of
                                            Technology
                                        </h3>
                                        <h4>
                                            Computer Systems Technology Diploma
                                        </h4>
                                        <h5>Predictive Analytics</h5>
                                        <h6>2020~2022</h6>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'experience' && (
                            <div className>
                                <h2 style={{ textDecoration: 'underline' }}>
                                    Work Experience
                                </h2>
                                <div className='experience__content'>
                                    <div className='tab-pane-bcaa'>
                                        <h3>Oooh</h3>
                                        <h4>
                                            Game Developer (Industry Sponsored
                                            Project)
                                        </h4>
                                        <h6>2022~2022</h6>
                                    </div>
                                    <div className='tab-pane-oooh'>
                                        <h3>BCAA</h3>
                                        <h4>Service Advisor</h4>
                                        <h6>2019~Present</h6>
                                    </div>
                                    <div className='tab-pane-ubc'>
                                        <h3>UBC KASA</h3>
                                        <h4>Event Director</h4>
                                        <h6>2018~2019</h6>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </Section>
    );
};

export default EducationExperience;
