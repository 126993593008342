import React from 'react';
import './navbar.css';
import { useState } from 'react';
import styled from 'styled-components';

const Navbar = () => {
    const [activeNav, setActiveNav] = useState('#');
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const Section = styled.div`
        scroll-snap-align: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        background-color: black;
    `;

    return (
        <Section>
            <div className='website__name'>
                <h2>sungjaehong</h2>
            </div>
            <nav className={isOpen ? 'navbar-open' : 'navbar-closed'}>
                <div className={`nav__list ${isOpen ? 'nav__list--open' : ''}`}>
                    <a
                        href='#header'
                        onClick={() => {
                            setActiveNav('#');
                            toggleMenu();
                        }}
                        className={activeNav === '#' ? 'active' : ''}
                    >
                        Home
                    </a>
                    <a
                        href='#about'
                        onClick={() => {
                            setActiveNav('#about');
                            toggleMenu();
                        }}
                        className={activeNav === '#about' ? 'active' : ''}
                    >
                        About
                    </a>
                    <a
                        href='#skills'
                        onClick={() => {
                            setActiveNav('#skills');
                            toggleMenu();
                        }}
                        className={activeNav === '#skills' ? 'active' : ''}
                    >
                        Skills
                    </a>
                    <a
                        href='#projects'
                        onClick={() => {
                            setActiveNav('#projects');
                            toggleMenu();
                        }}
                        className={activeNav === '#projects' ? 'active' : ''}
                    >
                        Projects
                    </a>
                    <a
                        href='#contact'
                        onClick={() => {
                            setActiveNav('#contact');
                            toggleMenu();
                        }}
                        className={activeNav === '#contact' ? 'active' : ''}
                    >
                        Contact
                    </a>
                </div>
                <button className='navbar-toggle' onClick={toggleMenu}>
                    <span
                        className={`navbar-toggle-icon ${isOpen ? 'open' : ''}`}
                    ></span>
                </button>
            </nav>
        </Section>
    );
};

export default Navbar;
