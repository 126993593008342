import React from 'react';
import './projects.css';
import TapIt from '../../assets/TapIt.png';
import styled from 'styled-components';
import ChargeUp from '../../assets/ChargeUp.png';
import ChipIn from '../../assets/ChipIn.png';
import Portfolio from '../../assets/Portfolio.png';
import Neighvery from '../../assets/Neighvery.png';
import ReCarmmendation from '../../assets/ReCarmmendation.png';

const data = [
    {
        id: 1,
        title: 'TapIt',
        img: TapIt,
        description:
            'Collaborated with Oooh to build a new module, a button tapping game with screen-recording feature and leaderboard feature for their app on the Appstore.',
        languages: "JavaScript, Oooh's custom framework",
        github: 'https://github.com/ParthCv/Tap-It-Oooh-ISSP',
        demo: '',
    },
    {
        id: 2,
        title: 'Portfolio Website',
        img: Portfolio,
        description:
            'Utilized React to build this portfolio website and Three.js to create the background animation.',
        languages: 'React, JavaScript, Three.js',
        github: 'https://github.com/sungjah/sungjae',
        demo: '',
    },
    {
        id: 3,
        title: 'Chipin',
        img: ChipIn,
        description:
            'Developed mobile application with Android Studio that allow users to pool funds together for various purposes such as gifts, eating out, etc.',
        languages: 'Java, Firebase, Android Studio',
        github: 'https://github.com/winter2022-comp3717/project-chippin',
        demo: '',
    },
    {
        id: 4,
        title: 'ChargeUp',
        img: ChargeUp,
        description:
            'Created electric charging station locator that will help drivers locate public charging stations and provide people with a platform to help other EV drivers by enabling them to rent out their own charging outlets.',
        languages: 'JavaScript, HTML/CSS, Firebase',
        github: 'https://github.com/sungjah/Team-BBY-11-ChargeUp',
        demo: 'https://chargeup-4b09d.web.app/map.html',
    },
    {
        id: 5,
        title: 'Neighvery',
        img: Neighvery,
        description: 'Created a simple food delivery web application.',
        languages: 'JavaScript, HTML/CSS, Firebase, Bootstrap',
        github: 'https://github.com/sungjah/Team27neighvery',
        demo: '',
    },
    {
        id: 6,
        title: 'ReCarmmendation',
        img: ReCarmmendation,
        description:
            'A project in progress to create a car maintenance recommendation based on the current mileage of the car. Community forums to talk about car related things.',
        languages: 'Python, Django, JavaScript, HTML/CSS',
        github: 'https://github.com',
        demo: '',
    },
];

const Projects = () => {
    const Section = styled.div`
        height: auto;
        scroll-snap-align: center;
    `;

    return (
        <Section>
            <section id='projects'>
                <h5>My Recent Work</h5>
                <h2>Projects</h2>
                <div className='commonBorder'></div>
                <div className='container projects__container'>
                    {data.map(
                        ({
                            id,
                            title,
                            img,
                            description,
                            languages,
                            github,
                            demo,
                        }) => {
                            return (
                                <article key={id} className='project__item'>
                                    <div className='project__item_img'>
                                        <img src={img} alt='TapIt' />
                                    </div>
                                    <h3>{title}</h3>
                                    <div className='description'>
                                        <p>{description}</p>
                                    </div>
                                    <br />
                                    <div className='languages__tools'>
                                        <h4>Languages and Tools:</h4>
                                        <p>{languages}</p>
                                    </div>
                                    <br />
                                    <div className='project__item_cta'>
                                        <a
                                            href={github}
                                            className='btn'
                                            target='_blank'
                                        >
                                            GitHub
                                        </a>
                                        {demo !== '' && (
                                            <a
                                                href={demo}
                                                className='btn btn-primary'
                                                target='_blank'
                                            >
                                                Live Demo
                                            </a>
                                        )}
                                    </div>
                                </article>
                            );
                        }
                    )}
                </div>
            </section>
        </Section>
    );
};

export default Projects;
