import React from 'react';
import './footer.css';
import styled from 'styled-components';

const Footer = () => {
    const Section = styled.div`
        scroll-snap-align: center;
    `;
    return (
        <Section>
            <footer>
                <p>&copy; {new Date().getFullYear()} SungJae Hong</p>
            </footer>
        </Section>
    );
};

export default Footer;
