import React from 'react';
import MyPhoto from '../../assets/myPhoto.jpg';
import './about.css';
import styled from 'styled-components';

const About = (props) => {
    const Section = styled.div`
        height: auto;
        scroll-snap-align: center;
    `;
    return (
        <Section>
            <section id='about'>
                <h5>Get To Know</h5>
                <h2>About Me</h2>
                <div className='commonBorder'></div>
                <div className='container about__container'>
                    <div className='para'>
                        <h2>Hello and Welcome!</h2>
                        <br />
                        <br />
                        My name is Jae, and I'm excited to share my journey with
                        you. I hold a Bachelor of Arts degree from the
                        University of British Columbia (UBC), where I majored in
                        Sociology and minored in Law and Society. My time at UBC
                        provided me with a strong foundation in critical
                        thinking, research, and analysis, which are valuable
                        skills that I've been able to apply to my work in
                        computer science.
                        <br />
                        <br />
                        While I was working as an insurance advisor, I realized
                        my passion for technology and decided to pursue a career
                        in computer science. Recently, I completed the Computer
                        Systems Technology program at the British Columbia
                        Institute of Technology (BCIT), where I gained hands-on
                        experience with programming languages such as Python,
                        Java, C, and JavaScript. I'm thrilled to continue
                        learning and developing my skills in this dynamic field.
                        With a well-rounded background and a thirst for
                        knowledge, I'm excited to continue growing as a computer
                        science professional and contributing to the field
                        through innovative solutions and creative
                        problem-solving.
                        <br />
                        Thank you for visiting, and I hope you enjoy exploring
                        my work.
                        <div className='myPhoto'>
                            <img src={MyPhoto} alt='Jae Hong' className='jae' />
                        </div>
                    </div>
                </div>
            </section>
        </Section>
    );
};

export default About;
