import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/header/Header';
import Navbar from './components/navbar/Navbar';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Projects from './components/projects/Projects';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import EducationExperience from './components/eduexp/EducationExperience';
import Background from './components/background/Background';
import styled from 'styled-components';

function App() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [position] = useState([5, 1, 4]);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const shouldRenderBackground = screenWidth >= 768;

    const Container = styled.div`
        height: auto;
    `;

    return (
        <Container>
            {shouldRenderBackground && <Background position={position} />}
            <div>
                <Navbar />
                <Header />
                <About />
                <EducationExperience />
                <Skills />
                <Projects />
                <Contact />
                <Footer />
            </div>
        </Container>
    );
}

export default App;
