import React from 'react';
import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import {
    OrbitControls,
    PerspectiveCamera,
    ScrollControls,
} from '@react-three/drei';
import { Ground } from './Ground';
import { Car } from './Car';

function CarShow(props) {
    return (
        <>
            <OrbitControls
                target={[0, 0.35, 0]}
                maxPolarAngle={1.45}
                autoRotate={false}
                autoRotateSpeed={1}
                enableZoom={false}
            />
            <PerspectiveCamera makeDefault fov={40} position={props.position} />
            <ScrollControls pages={3} damping={0.25}>
                <color args={[0, 0, 0]} attach='background' />
                <ambientLight intensity={0.09} />
                <Car />
                <spotLight
                    color={[1, 0.25, 0.7]}
                    intensity={2.5}
                    angle={0.6}
                    penumbra={0.5}
                    position={[5, 5, 0]}
                    castShadow
                    shadow-bias={-0.0001}
                />
                <spotLight
                    color={[0.14, 0.5, 1]}
                    intensity={2.5}
                    angle={0.6}
                    penumbra={0.5}
                    position={[-5, 5, 0]}
                    castShadow
                    shadow-bias={-0.0001}
                />
                <Ground />
            </ScrollControls>
        </>
    );
}

function Background(props) {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: -1,
            }}
        >
            <Suspense fallback={null}>
                <Canvas shadows>
                    <CarShow position={props.position} />
                </Canvas>
            </Suspense>
        </div>
    );
}

export default Background;
