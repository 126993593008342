import React from 'react';

import Typewriter from 'typewriter-effect';

export default function Type() {
    return (
        <div style={{ fontSize: '24px' }}>
            <Typewriter
                options={{
                    strings: [
                        'Software Developer',
                        'Web Developer',
                        'Car Enthusiast',
                    ],
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 50,
                }}
            />
        </div>
    );
}
