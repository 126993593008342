import React from 'react';
import { useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Mesh } from 'three';
export function Car() {
    const gltf = useLoader(
        GLTFLoader,
        process.env.PUBLIC_URL + 'models/car/scene.gltf'
    );
    console.log(gltf.scene.children[0].children[0].children);

    useEffect(() => {
        gltf.scene.scale.set(0.02, 0.02, 0.02);
        gltf.scene.position.set(1.2, -1.5, 1.5);
        gltf.scene.traverse((object) => {
            if (object instanceof Mesh) {
                object.castShadow = true;
                object.receiveShadow = true;
                object.material.envMapIntensity = 20;
            }
        });
    }, [gltf]);
    return <primitive object={gltf.scene} />;
}
